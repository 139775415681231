.controll-panel-container{
    display: flex;
    flex-direction: column;

    .btn {
        position: relative;
        margin: 5px;
        width: 200px;
        height: 45px;
    }
    
    .btn-start.start {
        transition: all 0.5s ease;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }
    .btn-start::before {
        content: "";
        border-radius: 11px;
        position: absolute;
        background: linear-gradient(145deg, rgb(0, 255, 13),
         rgb(0, 170, 23));
        width: 0%;
        top:0;
        bottom: 0;
        left: 0;
    }
    
    .btn-start:hover {
        color: greenyellow !important;
        text-shadow: 0 0 5px greenyellow !important;
    }
    
    .btn-start.start::before {
        animation: progress-anim 36s ease 0s;
    }
    
    .btn-start.cancel::after {
        content: "Server Online";
        color: rgb(21, 255, 0);
        position: absolute;
        left: -212px;
        top: 9px;
    
    }
    
    .btn-start.start {
        text-indent: 250px;
    }

    .btn-stop:hover{
        color: red;
        text-shadow: 0 0 5px red;
    }
    .cp-section{
        padding: 10px;
        position: relative;
        border: 2px solid #4d565f;
        border-radius: 20px;
    }
    
    .cp-title{
        position: absolute;
        top:-14px;
        left: 50%;
        color: #4d565f;
        transform: translate(-50%,0);
        background: #181b1f;
        user-select: none;
        padding: 0 10px;
        white-space: nowrap;
    }
}



@keyframes progress-anim {
	0% {
		width: 0%;
	}
	5% {
		width: 5%;
	}
	10% {
		width: 15%;
	}
	30% {
		width: 40%;
	}
	50% {
		width: 55%;
	}
	80% {
		width: 100%;
	}
	95% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}

