.message {
    text-align: left;
    margin-left: 5px;
}

.default {
    color:white;
}

.green {
    color:greenyellow;
}
.yellow {
    color:yellow;
}