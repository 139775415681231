.server-view{
    padding-top: 100px;
    width: calc(100% - 60px);
    margin: auto;
    display: flex;
    flex-direction: row;
}

.terminal {
    width: 70%;
}

.controll-panel {
    margin-right: 100px;
}