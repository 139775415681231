@import url('https://fonts.googleapis.com/css?family=Poppins');


$main-color: #181b1f;
$main-color-dark: #1d2024;
html, body {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    margin: 0;
    background: $main-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

div {
    font-family: 'Poppins';
}

input {
    border:none;
    outline: none;
    color: white;
    padding: 10px;
    padding-left: 20px;
    border-radius: 10px;
}

.btn {
    color: rgb(197, 197, 197);
    letter-spacing: 1px;
    font-size: 18px;
    outline: none;
    border: none;
    border: 3px solid $main-color;
    border-radius: 14px;
    background: $main-color;
    box-shadow:  7px 7px 13px #0a0b0c,
             -7px -7px 13px #262b32;
             transition: all 0.2s ease;
}


.btn:hover {
    cursor: pointer;
    color:rgba(0, 151, 252, 0.993);
    text-shadow: 0 0 5px rgba(0, 193, 252, 0.767);
    background: linear-gradient(145deg, #16181c, #1a1d21);
    box-shadow:  7px 7px 13px #0a0b0c,
                 -7px -7px 13px #262b32;
    
}

.btn:active {
    box-shadow: 7px 7px 13px #0a0b0c,
    -7px -7px 13px #262b32,
                inset 7px 7px 13px #0a0b0c,
                inset -7px -7px 13px #262b32;
}

.btn.active {
    box-shadow: 7px 7px 13px #0a0b0c,
    -7px -7px 13px #262b32,
                inset 7px 7px 13px #0a0b0c,
                inset -7px -7px 13px #262b32;
}

.flat {
    background: #181b1f;
    box-shadow:  7px 7px 13px #0a0b0c,
             -7px -7px 13px #262b32;
}

.concave {
    background: linear-gradient(145deg, #16181c, #1a1d21);
    box-shadow:  7px 7px 14px #0f1114,
             -7px -7px 14px #21252a;
}

.convex {
    background: linear-gradient(145deg, #1a1d21, #16181c);
    box-shadow:  7px 7px 14px #0f1114,
                 -7px -7px 14px #21252a;
}

.pressed {
    background: #181b1f;
    box-shadow: inset 7px 7px 14px #101316,
                inset -7px -7px 14px #31363d;
}

$neon-main-color: rgba(255, 255, 255, 0.692);
$neon-second-color: rgba(214, 214, 214, 0.61);
$neon-glow-color: rgb(0, 204, 255);
$neon-glow-color-2:rgba(0, 204, 255, 0.432);

.neon-text {
    color: black;
  text-shadow:
      1px 1.5px 0px rgba(0, 0, 0, 0.342),
      0 0 10px $neon-second-color,
      0 0 15px $neon-glow-color-2,
}

.neon-text:hover {
    animation: flicker 2s infinite alternate; 
}

@keyframes flicker {
    0%, 19%, 21%, 25%, 53%, 57%, 100% {
    color: $neon-main-color;
      text-shadow:
        0 0 4px $neon-main-color,
        0 0 11px $neon-main-color,
        0 0 19px $neon-main-color,
        0 0 30px $neon-glow-color,
        0 0 40px $neon-glow-color,
        0 0 42px $neon-glow-color,
        0 0 45px $neon-glow-color,
        0 0 50px $neon-glow-color;
    }
    20%, 24%, 55% {       
      text-shadow: none;
    }
  }
